<template>
  <Breadcrumb />
  <div v-if="isSuccess" class="alert alert-success mb-1">
    {{
      $t(
        "Messages.SuccessMessage",
        {},
        { locale: this.$store.state.activeLang }
      )
    }}
  </div>
  <div v-if="errors.length > 0" class="alert alert-warning mb-1">
    <ul class="mb-0">
      <li v-for="error in errors" v-bind:key="error">
        {{ error }}
      </li>
    </ul>
  </div>
  <div class="card">
    <div class="card-header">
      <div
        class="nav card-header-tabs w-100 nav-pills nav-justified pb-2"
        role="tablist"
      >
        <button
          class="nav-link mt-2 active me-1"
          id="project-information-tab"
          data-bs-toggle="tab"
          data-bs-target="#project-information-content"
          type="button"
          role="tab"
          aria-selected="true"
        >
          {{
            $t(
              "Projects.ProjectInformation",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</button
        ><button
          class="nav-link mt-2 me-1"
          id="database-settings-tab"
          data-bs-toggle="tab"
          data-bs-target="#database-settings-content"
          type="button"
          role="tab"
          aria-selected="false"
        >
          {{
            $t(
              "Projects.DatabaseInformation",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</button
        ><button
          class="nav-link mt-2"
          id="user-information-tab"
          data-bs-toggle="tab"
          data-bs-target="#user-information-content"
          type="button"
          role="tab"
          aria-selected="false"
        >
          {{
            $t(
              "Projects.UserInformation",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </div>
    </div>
    <div class="card-body">
      <form class="row g-3 form-project-new" novalidate>
        <div class="tab-content">
          <div
            class="tab-pane fade show active"
            id="project-information-content"
            role="tabpanel"
          >
            <div class="row mt-2 mb-2">
              <div class="col-md-6 mb-2">
                <label for="Name" class="form-label required"
                  >{{
                    $t(
                      "Projects.Name",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="Name"
                  @keyup="companyNameKeyup()"
                  v-model="projectData.name"
                  :autocomplete="$isAutoComplete"
                  :spellcheck="$isTextSpellCheck"
                  required
                />
              </div>
              <div class="col-md-6">
                <label for="FormulaName" class="form-label required">{{
                  $t(
                    "Projects.FormulaName",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  type="text"
                  class="form-control text-uppercase"
                  id="FormulaName"
                  @keyup="formulaNameKeyup()"
                  v-model="projectData.formulaName"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
              <div class="col-md-6">
                <label for="AppName" class="form-label required"
                  >{{
                    $t(
                      "Projects.CompanyName",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="AppName"
                  v-model="projectData.appName"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="database-settings-content"
            role="tabpanel"
          >
            <div class="row mt-2 mb-2">
              <div class="col-md-6">
                <label class="form-label"
                  >{{
                    $t(
                      "Projects.Type",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <FormSelect
                  type="SelectWithLocalData"
                  :selectedData="selectedType"
                  :data="typeData"
                  :allowEmpty="false"
                  @onChange="onChangeForType"
                />
              </div>
              <div class="col-md-6">
                <label class="form-label required"
                  >{{
                    $t(
                      "Projects.Instance",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <FormSelect
                  type="SelectWithRemoteData"
                  @onChange="onChangeForInstance"
                  :allowEmpty="false"
                  :isGatewayRequest="true"
                  :resetSelect="resetSelects"
                  requestUrl="/Lcdp-SummaryInstances"
                />
              </div>
              <div class="col-md-12 mt-2" v-if="cloneCompany">
                <div class="col-md-6">
                  <label class="form-label"
                    >{{
                      $t(
                        "Projects.Company",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <FormSelect
                    type="SelectWithRemoteData"
                    :allowEmpty="false"
                    :resetSelect="resetSelects"
                    :isDisabled="true"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="user-information-content"
            data-index="0"
            role="tabpanel"
          >
            <div class="row mb-2 mt-2">
              <div class="col-md-6 mb-3">
                <label for="FirstName" class="form-label required"
                  >{{
                    $t(
                      "Projects.FirstName",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  v-model="projectData.firstName"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
              <div class="col-md-6">
                <label for="LastName" class="form-label required"
                  >{{
                    $t(
                      "Projects.LastName",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="LastName"
                  v-model="projectData.lastName"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
              <div class="col-md-6">
                <label for="Email" class="form-label required"
                  >{{
                    $t(
                      "Projects.Email",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="Email"
                  v-model="projectData.email"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
              <div class="col-md-3">
                <label for="Password" class="form-label required"
                  >{{
                    $t(
                      "Projects.Password",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="password"
                  class="form-control"
                  id="Password"
                  @keyup="validatePassword()"
                  v-model="projectData.password"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
              <div class="col-md-3">
                <label for="PasswordAgain" class="form-label required"
                  >{{
                    $t(
                      "Projects.PasswordAgain",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                  <small class="text-danger" v-if="passwordDontMatch"
                    >({{
                      $t(
                        "BaseModelFields.PasswordDontMatch",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }})</small
                  ></label
                >
                <input
                  type="password"
                  class="form-control"
                  @keyup="validatePassword()"
                  id="PasswordAgain"
                  v-model="passwordAgain"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div
      class="bg-light step-action-buttons p-2"
      v-if="this.$store.getters._isSuperAdmin"
    >
      <ActionButtons
        classes="d-flex justify-content-center"
        @methodSave="onSubmit('0', 'btn-save')"
        @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
        @clear="onClear()"
        :isNewRecord="true"
      />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "ProjectNew",
  components: {},
  data() {
    return {
      selectedType: [
        {
          key: "true",
          value: this.$t(
            "Projects.BlankProject",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      typeData: [
        {
          key: "true",
          value: this.$t(
            "Projects.BlankProject",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "false",
          value: this.$t(
            "Projects.Clone",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      projectData: {
        isBlankProject: true,
        IsCompanyCacheNeeded: false,
      },
      resetSelects: false,
      instanceData: [],
      cloneCompany: false,
      isSuccess: false,
      passwordDontMatch: false,
    };
  },
  methods: {
    validationTabs() {
      if (
        String.isNullOrWhiteSpace(this.projectData.name) ||
        String.isNullOrWhiteSpace(this.projectData.formulaName) ||
        String.isNullOrWhiteSpace(this.projectData.appName)
      ) {
        $("#project-information-tab").trigger("click");
        return false;
      } else if (String.isNullOrWhiteSpace(this.projectData.instancePublicId)) {
        $("#database-settings-tab").trigger("click");
        return false;
      } else if (
        String.isNullOrWhiteSpace(this.projectData.firstName) ||
        String.isNullOrWhiteSpace(this.projectData.lastName) ||
        String.isNullOrWhiteSpace(this.projectData.email) ||
        String.isNullOrWhiteSpace(this.projectData.password) ||
        String.isNullOrWhiteSpace(this.passwordAgain)
      ) {
        $("#user-information-tab").trigger("click");
        return false;
      }

      return true;
    },
    validatePassword() {
      this.passwordDontMatch = this.projectData.password !== this.passwordAgain;
      if (this.passwordDontMatch) {
        $("#PasswordAgain").addClass("is-invalid");
        $("#PasswordAgain").removeClass("is-valid");
      } else {
        $("#PasswordAgain").removeClass("is-invalid");
        $("#PasswordAgain").addClass("is-valid");
      }
    },
    companyNameKeyup: function () {
      if (String.isNullOrWhiteSpace(this.projectData.name)) {
        this.projectData.formulaName = "";
        return;
      }
      this.projectData.formulaName = String.replaceAlphanumeric(
        this.projectData.name,
        "_"
      ).toUpperCase();
    },
    formulaNameKeyup() {
      this.projectData.formulaName = String.replaceAlphanumeric(
        this.projectData.formulaName,
        "_"
      ).toUpperCase();
    },
    onChangeForInstance: function (selected) {
      this.projectData.instancePublicId = selected.key;
    },
    onChangeForType: function (selected) {
      this.projectData.isBlankProject = Boolean.toBool(selected.key);
      if (!Boolean.toBool(selected.key)) {
        this.cloneCompany = true;
      } else {
        this.cloneCompany = false;
      }
    },
    onClear() {
      var projectIsBlank = this.projectData.isBlankProject;
      this.projectData = {
        isBlankProject: projectIsBlank,
      };
      this.resetSelects = !this.resetSelects;
      this.cloneCompany = false;
      $(".form-project-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-project-new");
      form.addClass("was-validated");

      if (!this.validationTabs()) {
        return;
      }

      if (
        !form[0].checkValidity() ||
        this.passwordDontMatch ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        if (this.passwordDontMatch) {
          this.validatePassword();
        }
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }
      var model = { ...this.projectData };
      model.email = this.$root.encrypt(model.email, true);
      model.password = this.$root.encrypt(model.password, true);

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-ArgesetNewCompany", model)
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push("/Project/List");
            } else {
              $("#project-information-tab").trigger("click");
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
};
</script>
